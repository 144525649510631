<template>
  <div>
    <div class="grid grid-cols-1 text-white">
      <div
        class="bg-nimbus-primary"
      />
    </div>

    <div
      class="flex px-8 bg-nimbus-primary items-center text-center py-5 gap-2"
      style="background: linear-gradient(90deg, #23B2B5, #097D81);"
    >
      <div class="hidden md:inline-block">
        <a href="/">
          <nimbus-logo />
        </a>
      </div>
      <div class="md:hidden">
        <a href="/">
          <img
            src="/images/cloud-default.svg"
            alt="Nimbus Hosting"
            width="50"
          >
        </a>
      </div>
      <div class="flex-1 flex justify-between items-center" />
      <div class="lg:flex lg:items-center lg:w-auto w-full">
        <nav>
          <ul class="lg flex items-center justify-between poppins-font leading-5 text-lg text-white pt-4 lg:pt-0 list-none">
            <li class="inline-block">
              <a
                href="https://nimbushosting.co.uk/"
                class="lg:p-4 py-2 px-0 block border-b-2 border-transparent hover:border-nimbus-labels-yellow"
              >
                <span class="mr-1">{{ 'login-header.website' | trans }}</span>
              </a>
            </li>

            <li>
              <a
                href="/"
                class="lg:p-4 py-2 px-0 block border-b-2 border-nimbus-labels-yellow"
              >
                {{ 'login-header.platform' | trans }}</a>
            </li>
            <li>
              <a
                href="https://youraccount.nimbushosting.co.uk/clientarea.php"
                class="lg:p-4 py-2 px-0 block border-b-2 border-transparent hover:border-nimbus-labels-yellow"
              >
                {{ 'login-header.client-area' | trans }}
              </a>
            </li>
            <li>
              <a
                href="https://support.nimbushosting.co.uk/support/home"
                class="lg:p-4 py-2 px-0 block border-b-2 border-transparent hover:border-yellow-500"
              >
                {{ 'login-header.support' | trans }}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>

import NimbusLogo from "./NimbusLogo"

export default {
  name: "LoginHeader",
  components: {
    NimbusLogo
  },
}
</script>

<style scoped>

header {
    top: 0;
    position: fixed;
    z-index: 999;
    width: 100%;
}

.poppins-font {
    font-family: 'Poppins',sans-serif;
}

</style>